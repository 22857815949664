import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ToastContainer } from 'react-toastify';
import Search from './pages/components/Search';
// import 'react-toastify/dist/ReactToastify.min.css';
const root = ReactDOM.createRoot(document.getElementById('root'));

function isSubdomain() {
	var url = window.location.href;
	var parts = url.split('.');
	var length = parts.length;
	if (length > 2) {
	  return true;
	} else {
	  return false;
	}
  }

root.render(
  	<React.StrictMode>
		{isSubdomain() ? <App /> :  <Search/>}
		{/* {true ? <App /> :  <Search/>} */}
		<ToastContainer />
  	</React.StrictMode>
);

reportWebVitals();
