/* eslint-disable */
import axios from "axios";

function isSubdomain() {
  var url = window.location.href;
  var parts = url.split('.');
  var length = parts.length;
  if (length > 2) {
    return true;
  } else {
    return false;
  }
}

const url = isSubdomain() ? "/admin/api/v1/" : "https://hm.shoprr.app/admin/api/v1/"

const api = axios.create({

  //(1)build Urls ---------------------------------------------------------------------------------------------------------------------------------------------

  // baseURL: "/admin/api/v1/",

  //(2)Live Urls-------------------------------------------------------------------------------------------------------------------------------------------

  // baseURL:"https://hm.shoprr.app/admin/api/v1/",

  baseURL: url,
  
  //(3)local Urls-----------------------------------------------------------------------------------------------------------------
  // baseURL:"http://mysouq.makentry.vtech/api/v1/",

  headers: {
    "Content-Type": "application/json",
  },
});
export default api;
