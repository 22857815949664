/*eslint-disable*/

import React, { useState, useEffect, useRef, Suspense } from "react";
import { Tabs, Tab } from "react-bootstrap";
import api from "../../Config/api";
import commonApi from "../../Config/CommonApi";
import styled from 'styled-components';
import debounce from 'lodash.debounce';

const Container = styled.div`
  body {
    overflow-x: hidden;
  }
  .shopImg {
    width: 50%;
  }
.loading{
  width: 100%;
  padding-bottom:20px;
}
a{
  text-decoration: none;
}
.form {
  display: flex;
  align-self: center;
  width: 70%;
  height: 50px;
  margin-left: 30px;
  border: 0.25px solid rgba(0, 0, 0, 0.12);
  border-radius: 50px;
  padding: 5px;
}

.form i {
  color: #000;
  font-size: 15px;
  padding: 10px;
}

.shoprrHeader {
  display: flex;
  align-content: center;
}

.shoprrtxtIcon {
  width: 100%;
}

.shoprr {
  display: flex;
  align-items: center;
}

.shoprrImg {
  width: 55px;
}

div h1 {
  align-content: center;
}

.inp {
  outline: none;
  width: 750px;
  border-radius: 5px;
  border: none;
}

.toggleIcon {
  color: black;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

i {
  color: rgb(124, 121, 121);
}

.main {
  display: flex;
  height: 100%;
}

a:focus,
a:hover {
  background: rgba(0, 0, 0, 0.12);
  text-decoration: none;
  font-weight: 700;
}

.menuContainer {
  width: 16%;
  top: 0;
  position: fixed;
  right: 0;
  transition: all 0.5s;
  z-index: 9999;
  background: white;
  height: 100%;
}

.dropdownContainer {
  display: none;
  position: absolute;
  background: #fff;
  border-radius: 5px;
}

.dropdownContainer::before {
  content: "";
  border-top: 10px solid transparent;
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 0;
  top: -20px;
}

.dropdownContainer a {
  display: block;
  font-size: 14px;
  color: #333;
  padding: 8px 10px;
  font-family: arial, sans-serif;
  text-decoration: none;
}

.dropdownContainer a:hover {
  background: #333;
  color: #fff;
}

.dropdownContainer a:hover:nth-of-type(1) {
  border-radius: 5px 5px 0 0;
}

.dropdownContainer a:hover:last-child {
  border-radius: 0 0 5px 5px;
  background: #333;
}

.showMenu {
  width: 400px;
  height: 100%;
  display: inline-block;
  background: #fff;
  top: 0;
  transition: all 0.5s;
  z-index: 10000;
}

.menuBar {
  margin-top: 85px;
}

.menuWrapper {
  height: 100%;
  width: 100%;
}

.navClick {
  position: fixed;
  height: 87px;
  cursor: pointer;
  margin-right: 50px;
  float: right;
  transition: all 0.5s;
  z-index: 20000;
  right: 0;
  top: 22px;
}

.navClick .toggleIcon {
  background-color: #fff;
  color: #000;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.5s;
}

.navClick .toggleIcon.active,
.navClick .toggleIcon:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.showSS {
  display: inline-block;
}

.showXmark {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: #000;
  cursor: pointer;
  transition: background-color 0.5s;
}

.xmarkIcon {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -50px;
  border-radius: 50%;
}

.xmarkIcon:hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.showIcon {
  z-index: 100000;
  position: relative;
}

.shoprrTxt {
  display: flex;
  align-items: center;
}

.shoprrText {
  font-weight: bold;
  margin: auto 10px;
  display: flex;
  align-items: center;
  font-size: 20px;
}

ul {
  list-style: none;
  padding: 0;
}

ul > li {
  text-align: left;
}

ul > li > a {
  color: rgba(68, 68, 68, 0.99);
  padding: 15px;
  display: block;
  text-decoration: none;
  transition: all 0.5;
  font-family: arial, sans-serif;
  font-size: 16px;
}

ul > li > a:hover {
  color: #000;
}

ul > li > a:hover {
  color: #000;
  text-decoration: none;
  transition: all 0.5;
  background: rgba(0, 0, 0, 0.12);
}

.navClick .bar1,
.navClick .bar2,
.navClick .bar3 {
  width: 30px;
  height: 4px;
  background: #0bf;
  margin: 7px 0;
  transition: 0.4s;
  display: block;
}

.i-xMark {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.menuBack {
  width: 100%;
  display: flex;
  justify-content: right;
  border-radius: 50%;
  cursor: pointer;
  padding-right: 40px;
  position: absolute;
  top: 30px;
}

.menuBack:hover::after {
  border-right: 3px solid #333;
  border-bottom: 3px solid #333;
}

.menuBack:hover {
  color: #333;
}

.dropdowntoggleSS {
  position: relative;
}

.removeInp{
  margin-top: 2%;
}
.dropdowntoggleSS::after {
  content: "";
  position: absolute;
  border-left: 7px solid transparent;
  border-top: 7px solid #333;
  border-right: 7px solid transparent;
  border-bottom: 7px solid transparent;
  width: 0;
  margin-left: 6px;
  top: 22px;
}

.dropdowntoggleSS:hover::after {
  border-top: 7px solid #333;
}

.Box {
  display: flex;
  flex-wrap: wrap;
  margin-right: 15px;
  width: 560px;
}

.tab-content > .active {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.cardBody {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.cardContent {
  height: 140px;
  margin-left: -62px;
}

.col-md-4 {
  width: 23%;
}

.cardText {
  display: flex;
  margin-left: -41px;
  padding: 0;
}

.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 0;
}

.chevronRight {
  height: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-right: -110px;
  color: black;
  transition: all 0.5;
}

.chevronRight i {
  width: 50px;
  height: 50px;
  background-color: white;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.5s;
}

.chevronRight i:hover {
  background: rgba(0, 0, 0, 0.12);
}

.nav {
  padding-left: 15px;
}

.nav a {
  color: #333;
  font-size: 18px;
}

.mainContent {
  width: 100%;
  display: flex;
}

.nav-link:focus,
.nav-link:hover {
  color: #333;
}

li a {
  color: #333;
  text-decoration: none;
}

.wrapper {
  width: 100%;
  padding: 15px;
  width: 100%;
  text-align: center;
}

.tab-content {
  padding: 10px 20px;
}
.nav-tabs a {
  border: 0 !important;
  background-color: transparent !important;
}
.nav-tabs li.active {
  border-bottom: 2px solid #000;
}
.nav-tabs li {
  border-bottom: 2px solid transparent;
  --bs-nav-link-color: black;
}
.nav-tabs li a {
  width: 100%;
  text-align: center;
}
ul.nav.nav-tabs {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
}
.nav-tabs .nav-link{
  border: none;
}
.nav-tabs li button.active{
  border-bottom: 2px solid black;
}
.nav-tabs li {
  padding: 10px 10px 0px 10px;
  font-size: 18px;
  font-weight: 350;
}
.tabs-wrapper {
  position: relative;
}
span.left,
span.right {
  position: absolute;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  border-radius: 50%;
  left: -50px;
  top: 8px;
  cursor: pointer;
  color: #ffffff;
}
span.right {
  right: -50px;
  left: unset;
}

.scroller-btn.inactive {
  opacity: 0.3;
  pointer-events: none;
}
.card-title{
  font-size: 16px;
}
.card-text{
  font-size: 14px;
}

@media only screen and (min-width: 321px) and (max-width: 970px) {
  .mainContent {
    flex-direction: column;
  }
  .form {
    width: 100%;
  }
}

@media only screen and (min-width: 321px) and (max-width: 768px) {
  .main {
    display: flex;
    justify-content: center;
  }
  .form {
    width: 100%;
  }
  .showMenu {
    width: 340px;
    height: 100%;
  }
  .navClick {
    margin-right: auto;
  }
  .inp {
    width: 250px;
  }
  .shoprrHeader {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .mainContent {
    flex-direction: column;
  }
  .Box {
    margin-right: 0;
    width: 100%;
  }
  .cardContent {
    width: 33%;
    margin-left: -15px;
    display: flex;
    flex-wrap: nowrap;
  }
  .cardBody{
    width: 100%;
  }
  .tab-content{
    padding: 20px 0 0 0;
  }
  .cardText {
    margin-left: 0;
  }

 @media only screen and (max-width: 1024px) {
    .menuContainer{
      display: block;
      position: fixed;
      width: 50%;
      background: white;
    }
  }

}

`;
function SearchResult(props) {
  const inputRef = useRef();
  const [searchValue, setSearchValue] = useState(props.searchValue);
  const [showMenu, setShowMenu] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const [showSS, setShowSS] = useState(false);
  const [products, setProducts] = useState(null);
  const [shops, setShops] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleNavClick = () => {
    setShowMenu(!showMenu);
    setShowIcon(!showIcon);
  };

  const handleDropdowntoggleSSClick = () => {
    setShowSS(!showSS);
  };

  const debouncedSendRequest = debounce((text) => {
    api.get(commonApi.search + text).then((res) => {
      if (res.data.result) {
        setProducts(res.data.result.products)
        setShops(res.data.result.shops)
      } else {
        console.log(res);
      }
    });
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, 2000);

  const handleSearch = (event) => {
    setLoading(true);
    setSearchValue(event.target.value);
    setProducts(null)
    setShops(null)
    debouncedSendRequest(event.target.value);
  };

  const handleClearInput = () => {
    setSearchValue('');
  };


  useEffect(() => {
    inputRef.current.focus();
    setLoading(true);
    api.get(commonApi.search + props.searchValue).then((res) => {
      if (res.data) {
        setProducts(res.data.result.products)
        setShops(res.data.result.shops)
        setLoading(false);
      } else {
        console.log(res);
      }
    });
  }, []);

  return (
    <>
      <Container>
        <div className="navClick" onClick={handleNavClick}>
          <span>
            <i className={`toggleIcon fa-solid fa-ellipsis-vertical fa-xl ${showIcon ? 'active fa-duotone fa-solid fa-xmark' : ''}`}></i>
          </span>
        </div>
        <div className={`menuWrap ${showMenu ? 'menuWrapper' : ''}`} >
          <div className="menuContainer" style={{ display: showMenu ? 'block' : 'none' }}>
            <div className="mainMenu">
              <div className="menuBack"></div>
            </div>
            <div className="menuBar">
              <ul>
                <li><a href="#">Sign in</a></li>
                <li><a href="#">Track Order</a></li>
                <li><a href="#">Privacy</a></li>
                <li><a href="#">About</a></li>
                <li><a href="#">Terms</a></li>
                <li>
                  <a href="#" className="dropdowntoggleSS" onClick={handleDropdowntoggleSSClick}>Arabic</a>
                  <div className={`dropdownContainer ${showSS ? 'showSS' : ''}`}>
                    <a href="#">HTML</a>
                    <a href="#">React</a>
                    <a href="#">Angular</a>
                    <a href="#">Latest Angular</a>
                    <a href="#">Javascript</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="main">
          <div className="mainContent">
            <div className="shoprrContent">
              <div className="shoprrHeader">
                <div className="shoprrtxtIcon">
                  <div className="shoprr">
                    <div className="shoprrImg">
                      <svg
                        width="200"
                        height="87"
                        viewBox="0 0 307 87"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M55.7752 76.9304C37.2997 76.9304 22.3203 61.9643 22.3203 43.5051C22.3203 25.0459 37.2997 10.0798 55.7752 10.0798C74.2508 10.0798 89.2302 25.0459 89.2302 43.5051C89.2302 61.9643 74.2508 76.9304 55.7752 76.9304Z"
                          fill="#25D366"
                        />
                        <path
                          d="M69.5757 28.7746C68.7452 27.6376 67.7814 26.8078 66.7459 26.2137C62.0399 23.5298 56.5033 25.7527 52.8021 28.8566C51.664 29.8093 50.608 30.9156 49.716 32.1448C49.3571 32.6468 48.7932 34.1219 48.0038 33.6404C47.4604 33.3126 46.9477 32.2985 46.5991 31.7453C46.107 30.977 45.6764 30.1678 45.297 29.338C44.528 27.6478 43.39 27.6888 41.9751 28.1907C41.2471 28.4468 39.8835 28.7746 40.3346 29.9629C41.0626 31.8682 41.9546 33.7019 43.0824 35.3818C43.6566 36.2423 44.2922 37.0618 44.9894 37.8096C45.5226 38.3935 46.1172 38.9876 46.1172 39.8584C46.1275 40.7803 45.8507 41.7432 45.7686 42.6651C45.6866 43.6178 45.6764 44.5807 45.7174 45.5334C45.6251 52.8269 48.2293 59.3112 49.3264 61.4112C49.5622 61.8619 50.0441 62.0872 50.4747 61.9541L53.1712 60.9297C53.7043 60.7658 53.9709 60.1409 53.7556 59.557C52.6688 56.5659 51.7768 53.4723 51.2847 50.3274C51.0386 48.7499 50.895 47.1519 50.8848 45.5641C50.8745 44.7651 50.9053 43.9661 50.9668 43.1671C51.0078 42.5729 51.0283 42.409 51.582 42.5012C52.1664 42.6037 52.7098 42.8803 53.2737 43.0749C58.2668 44.8061 64.6236 44.5705 68.5709 40.9032C68.5914 40.8827 68.6017 40.8725 68.6222 40.852C71.657 38.0042 72.3132 32.5238 69.5757 28.7746ZM66.1 35.3511C65.9974 35.6174 65.8539 35.8735 65.6796 36.1091C64.4185 37.8403 62.0604 38.2296 60.1021 38.4242C58.7897 38.5472 57.4466 38.5062 56.124 38.2808C55.4165 38.1579 53.0174 37.9735 52.9661 36.8569C52.9251 35.9657 53.9094 35.1053 54.4118 34.5623C55.0679 33.8555 55.7856 33.2307 56.5648 32.6775C58.841 31.059 62.2552 29.3893 64.9619 31.3765C65.6591 31.8887 66.1717 32.739 66.305 33.6302C66.387 34.2345 66.305 34.8184 66.1 35.3511Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="shoprrTxt">
                      <p className="shoprrText text-center">shoprr.app</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <form className="form">
                <i className="fa-solid fa-magnifying-glass"></i>
                <input
                  className="inp"
                  type="text"
                  name="search"
                  id="inp"
                  placeholder="search product, shop"
                  onChange={handleSearch}
                  value={searchValue}
                  ref={inputRef} />
              </form>
              <div className="removeInp" onClick={handleClearInput} style={{ display: searchValue.length > 1 ? 'block' : 'none' }}>
                <i className="fa-duotone fa-solid fa-xmark fa-xl xmarkIcon"></i>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="tabs-wrapper">
            <Suspense fallback={<div>Loading...</div>}>
              <Tabs
                defaultActiveKey="all"
                id="uncontrolled-tab-example scroller"
                className="nav nav-tabs"
              >
                <Tab eventKey="all" title="All">
                  {loading ? <div className="loading">Loading...<br /></div> : ''}
                  {products &&
                    products.map((data) => (
                      <div className="cardBody tab-pane in active">
                        <div className="Box card mb-3">
                          <div className="cardContent row g-0">
                            <div className="col-md-5">
                              <img
                                className={data.product_image != null ? 'shopImg' : 'shopImg mt-4'}
                                src={data.product_image != null ? data.product_image : data.shop_logo}
                                alt=""
                              />
                            </div>
                            <div className="cardText col-md-5">
                              <div className="card-body">
                                <h4 className="card-title">{data && data.product_name}</h4>
                                <p className="card-text">
                                  <span>{data && data.sales_price}</span>
                                </p>
                                <p className="card-text">{data && data.shop_name}</p>
                              </div>
                              <a target="_blank" href={data.shop_url}><div className="chevronRight">
                                <i className="fa-solid fa-chevron-right fa-xl"></i>
                              </div></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {shops &&
                    shops.map((data) => (
                      <div className="cardBody tab-pane in active">
                        <div className="Box card mb-3">
                          <div className="cardContent row g-0">
                            <div className="col-md-5">
                              <img
                                className="shopImg mt-4"
                                src={data && data.shop_logo}
                                alt=""
                              />
                            </div>
                            <div className="cardText col-md-5">
                              <div className="card-body">
                                <h4 className="card-title">{data && data.shop_name}</h4>
                                <p className="card-text">
                                  <span style={{ textDecorationLine: 'line-through' }}></span>
                                </p>
                                <p className="card-text">{data && data.shop_name}</p>
                              </div>
                              <a target="_blank" href={data.shop_url}><div className="chevronRight">
                                <i className="fa-solid fa-chevron-right fa-xl"></i>
                              </div></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Tab>
                <Tab eventKey="product" title="Product">
                  {loading ? <div className="loading">Loading...<br /></div> : ''}
                  {products &&
                    products.map((data) => (
                      <div className="cardBody tab-pane in active">
                        <div className="Box card mb-3">
                          <div className="cardContent row g-0">
                            <div className="col-md-5">
                              <img
                                className={data.product_image != null ? 'shopImg' : 'shopImg mt-4'}
                                src={data.product_image != null ? data.product_image : data.shop_logo}
                                alt=""
                              />
                            </div>
                            <div className="cardText col-md-5">
                              <div className="card-body">
                                <h4 className="card-title">{data && data.product_name}</h4>
                                <p className="card-text">
                                  <span>{data && data.sales_price}</span>
                                </p>
                                <p className="card-text">{data && data.shop_name}</p>
                              </div>
                              <a target="_blank" href={data.shop_url}><div className="chevronRight">
                                <i className="fa-solid fa-chevron-right fa-xl"></i>
                              </div></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Tab>
                <Tab eventKey="shop" title="Shop">
                  {loading ? <div className="loading">Loading...<br /></div> : ''}
                  {shops &&
                    shops.map((data) => (
                      <div className="cardBody tab-pane in active">
                        <div className="Box card mb-3">
                          <div className="cardContent row g-0">
                            <div className="col-md-5">
                              <img
                                className="shopImg mt-4"
                                src={data && data.shop_logo}
                                alt=""
                              />
                            </div>
                            <div className="cardText col-md-5">
                              <div className="card-body">
                                <h4 className="card-title">{data && data.shop_name}</h4>
                                <p className="card-text">
                                  <span style={{ textDecorationLine: 'line-through' }}></span>
                                </p>
                                <p className="card-text">{data && data.shop_name}</p>
                              </div>
                              <a target="_blank" href={data.shop_url}><div className="chevronRight">
                                <i className="fa-solid fa-chevron-right fa-xl"></i>
                              </div></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </Tab>
              </Tabs>
            </Suspense>
          </div>
        </div>
      </Container>
    </>
  );
}

export default SearchResult;
