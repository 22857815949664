/*eslint-disable*/
import React, { useState } from "react";
import "swiper/css";
import "swiper/css/scrollbar";

import SearchResult from "./SearchResult";
import SearchForm from "./SearchForm";

function Search(props) {
  const [searchValue, setSearchValue] = useState('');

  const handleValueChange = (searchValue) => {
    setSearchValue(searchValue);
  };

  return (
    <>
      {searchValue.length > 1 ?  <SearchResult searchValue={searchValue} /> : <SearchForm onChange={handleValueChange}/>}
    </>
  );
}

export default Search;
