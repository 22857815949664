/*eslint-disable */
import React, { useState, useEffect } from "react";
import api from "../../Config/api";
import commonApi from "../../Config/CommonApi";
import { useParams, useNavigate, Link } from "react-router-dom";
import Moment from "react-moment";
import emty from "../../images/ooooorrrdddderrrr.png";
import toast, { Toaster } from "react-hot-toast";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
const MySwal = withReactContent(Swal);

function OrderDetails() {
  const SuccessToast = ({ data, message }) => {
    return (
      <Fragment>
        <div className="toastify-header">
          <div className="title-wrapper">
            <h6 className="toast-title">{message}</h6>
          </div>
        </div>
      </Fragment>
    );
  };

  const ErrorToast = ({ message }) => {
    return (
      <Fragment>
        <div className="toastify-header">
          <div className="title-wrapper">
            <h6 className="toast-title">Error!</h6>
          </div>
        </div>
        <div className="toastify-body">
          <span role="img" aria-label="toast-text">
            {message}
          </span>
        </div>
      </Fragment>
    );
  };

  const [show, setShow] = useState(false);
  const [checkoutshow, setcheckoutshow] = useState(false);
  const [checkoutshow1, setcheckoutshow1] = useState(false);
  const [checkoutshow2, setcheckoutshow2] = useState(false);
  const [checkoutshow3, setcheckoutshow3] = useState(false);
  const [categoryshow, setcategoryshow] = useState(false);
  const [filtershow, setfiltershow] = useState(false);
  const [checkordermobi, setcheckordermobi] = useState([]);
  const [checkordermobi1, setcheckordermobi1] = useState("");
  const [response, setResponse] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const checkOutClose = () => setcheckoutshow(false);
  const checkOutClose1 = () => setcheckoutshow1(false);
  const checkOutClose2 = () => setcheckoutshow2(false);
  const checkOutClose3 = () => setcheckoutshow3(false);
  const categoryClose = () => setcategoryshow(false);
  const filterClose = () => setfiltershow(false);
  // const [Settings, setSettings] = useState("");

  const navigate = useNavigate();

  const OpenTrackOrderList = () => {
    setcheckoutshow1(false);
    setcheckoutshow2(true);
  };

  const TrackOrderdetails = () => {
    setcheckoutshow2(false);
    setcheckoutshow3(true);
  };

  let { id, id2 } = useParams();


  const hendelOrderByOrderNumber = (data) => {
    navigate(`/orderdetails/${id}/${id2}`);
  };
  const getAllFunction = () => {
    api.get(commonApi.Orderid + `${id}/${id2}`).then((res) => {
if(res.data.success === true){
     
      setResponse(res.data)
}else{
  setResponse(res.data)


}
      let Res = res.data;
      let data = res.data.result.orders[0].order_details;
      let data1 = res.data.result.orders[0];

      setcheckordermobi(data);
      setcheckordermobi1(data1);

      if (res.data.success === true) {
        // setResponse('true');

        setcheckoutshow1(false);
        setcheckoutshow2(true);
      }
      else{


        // setResponse('false');

      }
    });
  };

  // api.get(commonApi.getAllSettings).then((res) => {
  //   let data = res.data.result;
  //   let Info_logo = data.infogram_data;

  //   // console.log(res);
  //   setSettings(data);
  //   setSettingsInfoLogo(Info_logo);
  // });

  useEffect(() => {
    getAllFunction();
    // localStorage.removeItem("English");
  }, []);


  // if (Response.success === undefined) {
    
  // }

  if (response.success === true) {
    MySwal.fire({
      icon: "success",
       title:'My Order !',
        text: response && response.message,
      // showConfirmButton: false
      showConfirmButton: false,
      timer: 1500})

      return (
        <>
          <div className="myprod_sec">
            <div className="container">
              <div className="trackList mt-5">
                <h3>
                  {" "}
                  My Order{" "}
                  <span
                    style={{ position: "absolute", right: "15px", top: "15px" }}
                  >
                    {" "}
                    {/* <i
                        onClick={checkOutClose2}
                        className="fa-solid fa-xmark"
                      ></i>{" "} */}
                  </span>{" "}
                </h3>
                <hr></hr>

                <div className="row">
                  {/* {OrderData ? (
                      OrderData.map((data) => ( */}
                  <div className="col-lg-3 mb-2">
                    <div className="trackList_card">
                      <ul>
                        <li>
                          {" "}
                          Order ID #{" "}
                          <b>
                            {checkordermobi1 && checkordermobi1.order_no}{" "}
                          </b>{" "}
                        </li>
                        <li>
                          {" "}
                          Phone : {" "}
                          {checkordermobi1 &&
                            checkordermobi1.customer &&
                            checkordermobi1.customer.contact_no1}
                        </li>
                        {/* <li> Total Qty : {data.Qty[0].count}</li> */}
                        {/* <li>
                        {" "}
                        Total Price :{" "}
                        {Settings && Settings.currency.code} {checkordermobi1 && checkordermobi1.total_amount} ${" "}
                      </li> */}
                        <li>
                          {" "}
                          Order Date : 
                          <Moment format="DD/MM/YYYY">
                            {checkordermobi1 && checkordermobi1.created_at}
                          </Moment>
                        </li>
                        <li>
                          {" "}
                          Address line 1 : {" "}
                          <span>
                            {" "}
                            {checkordermobi1 &&
                              checkordermobi1.manual_address}{" "}
                          </span>{" "}
                        </li>
                        {/* <li>
                          {" "}
                          Address line 2 : {" "}
                          <span>
                            {" "}
                            {checkordermobi1 &&
                              checkordermobi1.address}{" "}
                          </span>{" "}
                        </li> */}
                        <li>
                          {" "}
                          Delivery Type : {" "}
                          <span>
                            {" "}
                            {checkordermobi1 &&
                              checkordermobi1.delivery_status}{" "}
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          Order Status : {" "}
                          <span>
                            {" "}
                            {checkordermobi1 &&
                              checkordermobi1.order_status}{" "}
                          </span>{" "}
                        </li>
                        <li>
                          {" "}
                          Details : Items{" "}
                          <i
                            onClick={() => hendelOrderByOrderNumber()}
                            class="fa-solid fa-eye"
                          ></i>{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* ))
                    ) : (
                      <img
                        src={Ordernotfound}
                        style={{
                          width: "750px",
                          hight: "700px",
                          display: "block",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        alt=""
                      />
                    )} */}
                </div>
              </div>
            </div>
          </div>
        </>
      );
  } else if(response.success === false){
    MySwal.fire({
      icon: "warning",
      text: response && response.message,
      showConfirmButton: false,
      timer: 1500

    });

    return (
      <div className="myprod_sec">
        <div className="container">
          <div className="trackList">
            {/* <h3 style={{color:"red" ,marginTop:"100px" ,fontFamily:"",fontSize:"35px",fontWeight:"10px"}}>  Please enter valid  order id and contact no ! </h3> */}
            <div className="row">
              <div className="col-lg-3">
                <div className="trackList_card">
                  <ul>
                    <img
                      src={emty}
                      style={{
                        width: "550px",
                        hight: "500px",
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                      }}
                      alt=""
                    />
                  </ul>
                </div>
              </div>

              <div className="col-md-6 col-6 mt-10">
                <Link to="/">
                  <button
                    // onClick={()=>Homepage()}
                    id="go_backmodal"
                    className="checkBtns backBtns"
                    type="button"
                  >
                    {" "}
                    Back{" "}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else{

null


  }
}
export default OrderDetails;
